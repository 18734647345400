<template>
  <div class="border-style-template">
    <div ref="preview"></div>
  </div>
</template>

<script>
// import { BookRender } from 'book-preview'
import { NodeRender } from 'book-preview';
import { webFontFamily, replaceFontSizeMap } from '@/assets/styleTool/map';
import _ from 'lodash';

export default {
  props: {
    styleProp: {
      type: Object,
      required: true
    },
    currentStyle: {
      type: Object
    }
  },
  mounted () {
    this.render();
  },
  data () {
    return {
      nodeRender: new NodeRender({
        getFont: font => (webFontFamily)[font]
      })
    };
  },
  destroyed () {
    this.nodeRender.destroy();
  },
  computed: {
    content () {
      return [{
        node_id: '111',
        node_type: 'paragraph',
        node_level: 1,
        content: {
          body: '<p>这是一段方框的样例文字</p>'
        }
      }];
    },
  },
  methods: {
    handleFontSize (arg) {
      if (Array.isArray(arg)) {
        arg.forEach(item => this.handleFontSize(item));
        return;
      }
      if (arg && typeof arg === 'object') {
        Object.keys(arg).forEach(key => {
          if (key === 'font_size' && replaceFontSizeMap[arg[key]]) {
            arg[key] = replaceFontSizeMap[arg[key]];
            return;
          }
          this.handleFontSize(arg[key]);
        });
      }
    },
    render () {
      const config = _.cloneDeep(this.styleProp);
      config.components[0].page_cfg.global_column.column_count = 1;
      if (this.currentStyle) {
        config.components[0].group_cfg_list = [{
          id: '111',
          name: '111',
          node_id_list: ['111'],
          group_style_id: this.currentStyle.id
        }];
      }
      this.handleFontSize(config.components[0]);
      this.nodeRender
      .setData({ json: this.content, config })
      .preview({
        renderTo: this.$refs.preview
      });
    }
  },
  watch: {
    styleProp: {
      deep: true,
      handler () {
        this.render();
      }
    },
    currentBatch: {
      deep: true,
      handler () {
        this.render();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .border-style-template {
    height: 100%;
    overflow-y: auto;
  }
</style>
