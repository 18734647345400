<template>
  <el-card class="border-box">
    <split-pane :min-percent='20' :default-percent='30' split="vertical" class="body">
      <template slot="paneL">
        <div class="menu">
          <div class="title">
            <span>底纹样式列表</span>
            <el-button class="style-tool-button--text f-r-btn" @click="addConfig">+新增配置</el-button>
          </div>
          <div class="list">
            <div class="list-item" v-for="item in groupStyleList" :key="item.index" :index="item.id" @click="switchStyle(item)" :class="{ 'act-item': item.id === actStyle.id}">
              <div>{{ item.name }}</div>
              <div>
                <el-button type="text" class="del-button" @click.stop="editItem(item)">修改</el-button>
                <el-button type="text" class="del-button" @click.stop="delItem(item)">删除</el-button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="paneR">
        <el-tabs v-model="bantchActTab" class="bantch-tabs">
          <el-tab-pane label="样式配置" name="style"></el-tab-pane>
          <el-tab-pane label="节点配置" name="node"></el-tab-pane>
        </el-tabs>
        <div class="body-right" v-if="config && bantchActTab === 'style'">
          <div class="style-lib" :class="{ closed }">
            <div class="toggle" @click="closed=!closed">版式库 <i class="el-icon-caret-top"></i></div>
            <div class="condition-box">
              <div class="left">
              </div>
              <div class="right">
                <i class="el-icon-search"></i>
                <input type="text" placeholder="输入关键字" v-model="condition.search">
              </div>
            </div>
            <div class="style-box">
              <div class="item" v-for="(child, i) in formatDataList" :key="i + child.data.name" :class="{'act-item': actFormat.data.name===child.data.name}">
                <i class="el-icon-error del" @click.stop="delComponent(child)"></i>
                <el-image :src="child.data.img" alt="" class="img" fit="cover" />
                <div class="text-box">
                  <i class="el-icon-success" v-if="actFormat.data.name===child.data.name"></i>
                  <span>{{child.data.name}}</span>
                </div>
                <div class="button-box">
                  <el-button size="mini" class="button" @click="selectFormat(child)">套用</el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="preview">
            <BorderStylePreview :styleProp="styleData" :currentStyle="config" />
          </div>
          <div class="config">
            <el-button class="style-tool-button--text right-btn" @click.stop="setComponent" icon="el-icon-plus">生成版式</el-button>
            <el-tabs v-model="actComponent">
              <el-tab-pane label="整体配置" name="_self">
                <FkConfig v-model="config" :styleData="styleData" />
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </template>
    </split-pane>
    <el-dialog
      :title="this.actItem ? '修改方框配置' : '新增方框配置'"
      @closed="dialogClose"
      destroy-on-close
      :visible.sync="dialogVisible"
      width="600px">
      <el-form :model="form" :rules="rules" ref="form" label-width="140px">
        <el-form-item label="请输入配置名称：" prop="name">
          <el-input placeholder="请输入名称" style="width: 400px;" size="small" v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="text-align: center">
        <el-button type="primary" @click="confirm" size="mini">{{ this.actItem ? '确认修改' : '确认创建' }}</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import SplitPane from 'vue-splitpane';
import { defaultGroupStyle } from '@/scripts/styleToolDefault';
import { setId } from '@/scripts/helper';
import { catalog } from '../assets/componentCatalog.js';
import BorderStylePreview from '../components/BorderStylePreview';
import FkConfig from '../components/FkConfig';
import _ from 'lodash';
import { parser } from '@/scripts/convertLatex';
import { iterateNode } from '@/scripts/treeHelper.js';

export default {
  components: {
    SplitPane,
    BorderStylePreview,
    FkConfig
  },
  props: {
    styleData: {
      type: Object,
      required: true
    },
    json: {
      type: Array,
      required: true
    }
  },
  created () {
  },
  data () {
    return {
      latexParse: parser,
      filterText: '',
      actFormat: {
        data: {}
      },
      defaultProps: {
        children: '_children',
        label: 'node_name'
      },
      closed: false,
      actComponent: '_self',
      config: null,
      dialogVisible: false,
      actItem: null,
      actStyle: {},
      formatData: [],
      bantchActTab: 'style',
      tabIndex: 0,
      tabs: [
        { name: '方框样式列表', index: 0 },
      ],
      form: {
        name: ''
      },
      condition: {
        search: ''
      },
      rules: {
        name: [{ validator: this.validator, trigger: 'change' }]
      },
      conditions: {
        currentPage: 1,
        currentParent: null
      }
    };
  },
  computed: {
    nodeIdList () {
      return this.config?.node_id_list || [];
    },
    chapterChildren () {
      const chapters = [];
      for (const { node } of iterateNode(_.cloneDeep(this.json))) {
        if (node.node_type === 'chapter') {
          node._children = [];
          for (const { node: child } of iterateNode(node.children)) {
            node._children.push(child.node_id);
          }
          chapters.push({
            node_id: node.node_id,
            _children: node._children
          });
        }
      }
      return chapters;
    },
    checkedNodeIds () {
      const nodeIdList = this.config.node_id_list;
      const res = [];
      this.chapterChildren.forEach(({ node_id, _children }) => {
        if (_children.every(id => nodeIdList.includes(id))) res.push(node_id);
      });
      console.log(res);
      return res;
    },
    checked: {
      get () {
        const nodeIdList = this.config.node_id_list;
        return this.listData.list.every(item => nodeIdList.includes(item.node_id));
      },
      set (val) {
        const { list } = this.listData;
        if (list.length === 0) return;
        const nodeIdList = this.config.node_id_list;
        if (val) {
          list.forEach(item => {
            !nodeIdList.includes(item.node_id) && nodeIdList.push(item.node_id);
          });
        } else {
          list.forEach(item => {
            const index = nodeIdList.indexOf(item.node_id);
            if (index >= 0) nodeIdList.splice(index, 1);
          });
        }
      }
    },
    selectedList () {
      const nodeList = [];
      for (const { node } of iterateNode(this.json)) {
        nodeList.push(node);
      }
      const nodeIdList = this.config.node_id_list;
      const selectedList = nodeIdList.map(item => {
        const node = _.cloneDeep(nodeList.find(node => node.node_id === item));
        if (!node) return;
        const content = node.content;
        const div = document.createElement('div');
        div.innerHTML = content.body;
        content._body = div.innerText;
        return Object.assign(node, { content });
      });
      return selectedList.filter(item => item);
    },
    listData () {
      const { currentPage, currentParent } = this.conditions;
      if (!currentParent || !this.json) {
        return {
          list: [],
          filterList: [],
          total: 0
        };
      }
      const filterData = [];
      for (const { node } of iterateNode(_.cloneDeep(currentParent.children))) {
        const { body } = node.content;
        const temp = document.createElement('div');
        temp.innerHTML = body;
        node.content._body = temp.innerText;
        filterData.push(node);
      }
      const total = filterData.length;
      return {
        total,
        filterList: filterData,
        list: filterData.slice((currentPage - 1) * 20, (currentPage - 1) * 20 + 20),
      };
    },
    treeJson () {
      const json = _.cloneDeep(this.json);
      for (const { node } of iterateNode(json)) {
        if (node.node_type === 'chapter') {
          node._children = node.children.filter(item => item.node_type === 'chapter');
          node._node_name = node.node_name;
        }
      }
      console.log(json);
      return json;
    },
    formatDataList () {
      const { search } = this.condition;
      return this.formatData.filter(item => item.data.name.indexOf(search) >= 0);
    },
    formatList () {
      const formatList = catalog.module.borderStyle.model;
      if (!formatList) return [];
      return formatList;
    },
    groupStyleList () {
      return this.styleData.components[0].group_style_list;
    }
  },
  watch: {
    nodeIdList: {
      deep: true,
      handler () {
        this.$refs.tree?.setCheckedKeys(this.checkedNodeIds);
      }
    },
    filterText (val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    onClickCheck ({ node_id }, { checkedKeys }) {
      console.log('onClickCheck');
      const checked = checkedKeys.includes(node_id);

      // 当前选中节点下的子节点
      const ids = this.chapterChildren.find(item => item.node_id === node_id)._children;

      const nodeIdList = this.config.node_id_list;
      if (checked) {
        ids.forEach(id => {
          if (!nodeIdList.includes(id)) nodeIdList.push(id);
        });
      } else {
        ids.forEach(id => {
          const index = nodeIdList.indexOf(id);
          if (index >= 0) {
            nodeIdList.splice(index, 1);
          }
        });
      }
    },
    delNode (node_id) {
      const nodeIdList = this.config.node_id_list;
      const index = nodeIdList.indexOf(node_id);
      nodeIdList.splice(index, 1);
    },
    getItem (item) {
      return item?.content?._body;
    },
    handleNodeList (nodeId) {
      const nodeList = this.config.node_id_list;
      const index = nodeList.indexOf(nodeId);
      if (index >= 0) {
        nodeList.splice(index, 1);
      } else {
        nodeList.push(nodeId);
      }
    },
    onClickTree (data) {
      this.conditions.currentParent = data;
    },
    filterNode (value, data) {
      if (!value) {
        data.node_name = data._node_name;
        return true;
      }
      const hasKeyword = data._node_name.indexOf(value) !== -1;
      if (hasKeyword) {
        data.node_name = data._node_name.replace(value, `<span class="mark">${value}</span>`);
      } else {
        data.node_name = data._node_name;
      }
      return hasKeyword;
    },
    setComponent () {
      const config = _.cloneDeep(this.config);
      delete config.name;
      delete config.id;
    },
    selectFormat (item) {
      this.actFormat = item;
      Object.assign(this.config, _.cloneDeep(item.data.data));
    },
    switchStyle (val) {
      const { group_style_list } = this.styleData.components[0];
      if (val) {
        if (val.id === this.actStyle.id) return;
        this.actStyle = group_style_list.find(item => item.id === val.id);
      } else {
        this.actStyle = group_style_list[0];
      }
      this.config = this.actStyle;
    },
    editItem (val) {
      this.form = _.cloneDeep(val);
      this.dialogVisible = true;
    },
    delItem (val) {
      const { group_style_list } = this.styleData.components[0];
      this.$confirm('此操作将永久删除该配置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const index = group_style_list.findIndex(item => item.id === val.id);
        group_style_list.splice(index, 1);
        if (val.id === this.actStyle.id) {
          index > 0 ? this.switchStyle(group_style_list[index - 1]) : this.switchStyle();
        }
      }).catch(e => e);
    },
    confirm () {
      const { group_style_list } = this.styleData.components[0];
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        if (this.form.id) {
          const index = group_style_list.findIndex(item => item.id === this.form.id);
          if (index < 0) return this.$message.error('未知错误');
          group_style_list.splice(index, 1, this.form);
          this.actStyle = this.config = this.form;
        } else {
          const item = defaultGroupStyle({ id: setId(), name: this.form.name });
          group_style_list.push(item);
          this.actStyle = this.config = item;
        }
        this.dialogVisible = false;
      });
    },
    dialogClose () {
      this.form = { name: '' };
    },
    validator (rule, value, callback) {
      if (!value) {
        return callback(new Error('名字不能为空!'));
      }
      const repeatName = this.groupStyleList.find(item => item.name === value && item.id !== this.form.id);
      if (repeatName) return callback(new Error('名字已重复!'));
      callback();
    },
    addConfig () {
      this.dialogVisible = true;
    },
  }
};
</script>
<style lang="scss">
.style-tool-tooltip {
  overflow: auto;
}
.style-tool-mark {
  font-weight: 700;
  color: #606266;
  background-color: yellow;
}
.style-demo-border-box-config-pop {
  padding: 0;
  .style-demo-paragraph-config-pop-list {
    /deep/ .el-button--text {
      border: unset;
      background-color: unset;
      color:#198cff;
      font-size: 12px;
      padding: 0;
      min-width: unset;
      &:hover {
        background-color: unset;
        color:#66b1ff;
      }
    }
    .pop-header {
      height: 60px;
      padding: 0 12px 0 23px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e5e4e4;
      .pop-title {
        font-size: 16px;
        font-weight: 500;
        color: #18191A;
        flex: auto;
      }
      .pop-control {
        flex: none;
      }
    }
    .empty {
      height: 300px;
      line-height: 300px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #616665;
    }
    .pop-list {
      max-height: 300px;
      overflow-y: auto;
      .pop-list-item {
        height: 60px;
        border-bottom: 1px solid #e5e4e4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px 0 23px;
        overflow: hidden;
        .content {
          font-size: 14px;
          font-weight: 400;
          color: #616665;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
        /deep/ .el-button--text {
          color: #A2A2A2;
          &:hover {
            color: #d5d9de;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
$main-color: #409EFF;
$danger-color: #F56C6C;
.border-box {
  .selected-box {
    position: absolute;
    right: 0;
    top: -56px;
  }
  .pop-toggle {
    min-width: 0;
    padding: 10px;
  }
  .node-select-container {
    padding-top: 60px;
    height: 100%;
    .tree-container {
      border: 1px solid #ccc;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      height: 100%;
      .tree-title {
        font-size: 15px;
        font-weight: 500;
        height: 60px;
        line-height: 60px;
        text-align: center;
        flex: none;
      }
      .search-container {
        padding: 0 20px 10px;
        flex: none;
      }
      .tree {
        flex: auto;
        overflow-y: auto;
        /deep/ .mark {
          background-color: yellow;
        }
      }
    }
    .list {
      padding: 10px 20px;
      flex: auto;
      overflow-y: auto;
      border: 1px solid #ccc;
      height: 100%;
      display: flex;
      flex-direction: column;
      .list-header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: none;
        height: 40px;
        .list-header-left {
          padding-left: 10px;
          flex: auto;
        }
        .list-header-right {
          flex: none;
          display: flex;
          .style-tool-button--text {
            margin-right: 10px;
            color: rgb(48, 49, 51);
          }
          .main-color {
            color: $main-color
          }
          /deep/ .el-checkbox__label {
            width: unset;
            padding-right: 14px;
          }
        }
      }
      .list-container {
        border: 1px solid #DEDEDE;
        flex: auto;
        overflow: hidden;
      }
      .list-body {
        overflow-x: hidden;
        flex: auto;
        overflow-y: auto;
      }
      .list-item {
        font-size: 12px;
        height: 30px;
        color: #919599;
      }
      .footer {
        flex: none;
        text-align: center;
        padding: 10px;
      }
    }
  }
  .body-right {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }
  .bantch-tabs {
    position: absolute;
    left: 6px;
    top: -2px;
    width: calc(100% - 60px);
    /deep/ .el-tabs__header {
      margin: 0;
    }
    /deep/ .el-tabs__nav-wrap::after {
      height: 1px;
      background-color: #ccc;
    }
  }
  /deep/ .el-card__body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .menu {
    height: 100%;
    border: 1px solid #ccc;
    position: relative;
    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 60px;
      text-align: center;
      .f-r-btn {
        float: right;
        line-height: 60px;
        margin-right: 20px;
      }
    }
    .list {
      .list-item {
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        margin-bottom: 10px;
        background-color: #F2F6FC;
        height: 48px;
        line-height: 48px;
        padding: 0 20px;
        .del-button {
          background-color: unset;
          padding: 0;
          color: #A2A2A2;
          min-width: unset;
          font-size: 12px;
          border: none;
          &:hover {
            opacity: 0.8;
          }
        }
      }
      .act-item {
        background-color: #dae1e8;
      }
    }
  }
  .style-lib {
    flex: none;
    position: relative;
    max-height: 400px;
    border: 1px solid #ccc;
    margin-top: 40px;
    z-index: 1;
    .style-lib-tabs {
      padding: 0 20px;
      /deep/ .el-tabs__header {
        margin-bottom: unset;
      }
      /deep/ .el-tabs__item {
        height: 50px;
      }
      .tab-item {
        display: flex;
        flex-direction: column;
        .tab-item-img {
          flex: none;
          height: 30px;
        }
        .tab-item-text {
          flex: none;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          text-align: center;
        }
      }
    }
    .toggle {
      position: absolute;
      right: -1px;
      top: 0;
      transform: translate(0, -100%);
      border: 1px solid #ccc;
      border-bottom-color: transparent;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      padding: 10px 4px;
      background: #fff;
      cursor: pointer;
      .el-icon-caret-top {
        transition: transform .2s;
      }
    }
    .condition-box {
      display: flex;
      align-items: center;
      color: #333;
      height: 40px;
      overflow: hidden;
      transition: all 0.2s;
      .left {
        flex: auto;
        display: flex;
        align-items: center;
        overflow-x: auto;
        .format-item {
          font-size: 13px;
          padding: 2px 4px;
          padding-top: 0.1px;
          margin-left: 20px;
          color: #666;
          border-radius: 3px;
          cursor: pointer;
          &:hover {
            color: #fff;
            background: $main-color;
          }
        }
        .act-item {
          color: #fff;
          background: $main-color;
        }
      }
      .right {
        flex: none;
        display: flex;
        align-items: center;
        font-size: 13px;
        padding: 0 10px;
        i {
          color: #C0C4CC;
        }
        input {
          outline: none;
          border: none;
          border-bottom: 1px solid #ccc;
          padding-left: 10px;
          width: 120px;
          font-size: 13px;
          color: #919699;
          &::-webkit-input-placeholder {
            color: #DCDFE6;
          }
        }
      }
    }
    .style-box {
      transition: all 0.2s;
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      max-height: 200px;
      overflow-y: auto;
      .item {
        width: 220px;
        height: 90px;
        margin: 4px;
        border: 1px solid transparent;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 20px;
        cursor: pointer;
        position: relative;
        .del {
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(50%, -50%);
          z-index: 2;
          display: none;
        }
        .img {
          width: 90%;
          height: 40px;
          flex: none;
        }
        .button-box {
          flex: auto;
          align-items: center;
          justify-content: center;
          display: none;
        }
        .text-box {
          flex: auto;
          align-items: center;
          justify-content: center;
          display: flex;
          .el-icon-success {
            color: $main-color;
            margin-right: 6px;
          }
        }
        &:hover {
          border-color: $main-color;
        }
        &:hover {
          .del {
            display: unset;
          }
          .button-box {
            display: flex;
          }
          .text-box {
            display: none;
          }
        }
      }
      .act-item {
        background-color: #409eff1f;
      }
    }
  }
  .closed {
    border-color: transparent;
    .toggle {
      border: 1px solid #ccc;
      border-radius: 4px;
      .el-icon-caret-top {
        transform: rotate(180deg);
      }
    }
    .condition-box {
      height: 0;
    }
    .style-box {
      overflow: hidden;
      height: 0;
      padding: 0;
    }
    .style-lib-tabs {
      height: 0;
      overflow: hidden;
    }
  }
  .preview {
    flex: none;
    height: 200px;
    background-color: #F2F6FC;
    margin: 20px 0;
    padding: 10px;
  }
  .config {
    flex: auto;
    overflow-y: auto;
    position: relative;
    .right-btn {
      position: absolute;
      right: 0;
      top: 0;
      line-height: 40px;
      z-index: 2;
    }
    /deep/ .el-radio__label {
      width: unset;
    }
    /deep/ .el-checkbox__label {
      width: unset;
    }
    /deep/ .el-form-item {
      margin-bottom: 0;
      padding: 10px;
      margin-right: unset;
    }
    .label, /deep/ .el-form-item__label, /deep/ .el-form-item__content {
      font-size: 14px;
      line-height: 17px;
      color: #000;
    }
    /deep/ .el-form-item__label {
      padding-right: 0;
    }
    /deep/ .el-tabs {
      height: 100%;
      .el-tabs__header {
        padding-right: 80px;
      }
      .el-tabs__content {
        overflow-y: auto;
        height: calc(100% - 55px);
      }
    }
  }

  /deep/ .splitter-pane-resizer {
    background: #fff;
    opacity: 1;
    // border: 1px solid #0003;
    z-index: 1111;
    // box-shadow: 0px 0px 3px #bdbfb4;
  }
  /deep/ .splitter-paneR {
    padding-left: 6px!important;
  }
  /deep/ .splitter-paneL {
    padding-right: 5px!important;
  }
}
</style>
