<template>
  <div class="fk-config">
    <input ref="file" type="file" class="file-input" accept="*" :value="fileVal" @change="fileChange">
    <el-form :inline="true" size="mini" label-width="70px">
      <el-form-item label="方框类型：">
        <el-select v-model="value.border_type" placeholder="系统边框" :popper-append-to-body="false" clearable @change="handleBorderType" class="style-tool-select">
          <el-option label="系统边框" value="default"></el-option>
          <el-option label="图片边框" value="image"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="线类型：" v-if="value.border_type==='default' || value.border_type === ''">
        <el-select v-model="value.border.type" placeholder="实线" :popper-append-to-body="false" clearable class="style-tool-select">
          <el-option
            v-for="item in borderType"
            :key="item.value"
            :label="item.key"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <br>
      <el-form-item label="上外边距：">
        <format-input v-model="value.margin.top" clearable placeholder="0mm" />
      </el-form-item>
      <el-form-item label="下外边距：">
        <format-input v-model="value.margin.bottom" clearable placeholder="0mm" />
      </el-form-item>
      <el-form-item label="左外边距：">
        <format-input v-model="value.margin.left" clearable placeholder="0mm" />
      </el-form-item>
      <el-form-item label="右外边距：">
        <format-input v-model="value.margin.right" clearable placeholder="0mm" />
      </el-form-item>
      <br>
      <el-form-item label="上内边距：">
        <format-input v-model="value.padding.top" clearable placeholder="0mm" />
      </el-form-item>
      <el-form-item label="下内边距：">
        <format-input v-model="value.padding.bottom" clearable placeholder="0mm" />
      </el-form-item>
      <el-form-item label="左内边距：">
        <format-input v-model="value.padding.left" clearable placeholder="0mm" />
      </el-form-item>
      <el-form-item label="右内边距：">
        <format-input v-model="value.padding.right" clearable placeholder="0mm" />
      </el-form-item>
      <br>
      <el-form-item label="背景色：">
        <Color :value="value.background" defaultColor="rgb(0, 0, 0)" :globalColor="styleData.book_default_style.global_color" />
      </el-form-item>
      <br>
      <el-form-item label="顶部图片：">
        <span class="buttons">
          <el-badge :is-dot="!!value.border_image.header" class="m-r-10">
            <el-button @click="upload('header')">上传</el-button>
          </el-badge>
          <el-button class="cancel" @click="delUrl('header')">删除</el-button>
        </span>
      </el-form-item>
      <el-form-item label="底部图片：">
        <span class="buttons">
          <el-badge :is-dot="!!value.border_image.footer" class="m-r-10">
            <el-button @click="upload('footer')">上传</el-button>
          </el-badge>
          <el-button class="cancel" @click="delUrl('footer')">删除</el-button>
        </span>
      </el-form-item>
      <el-form-item label="左侧图片：">
        <span class="buttons">
          <el-badge :is-dot="!!value.border_image.left" class="m-r-10">
            <el-button @click="upload('left')">上传</el-button>
          </el-badge>
          <el-button class="cancel" @click="delUrl('left')">删除</el-button>
        </span>
      </el-form-item>
      <el-form-item label="右侧图片：">
        <span class="buttons">
          <el-badge :is-dot="!!value.border_image.right" class="m-r-10">
            <el-button @click="upload('right')">上传</el-button>
          </el-badge>
          <el-button class="cancel" @click="delUrl('right')">删除</el-button>
        </span>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import FormatInput from './FormatInput';
import Color from './Color';

export default {
  components: {
    FormatInput,
    Color
  },
  name: 'column-config',
  props: {
    value: {
      required: true,
      type: Object
    },
    styleData: {
      type: Object,
      default () {
        return {
          book_default_style: {
            global_color: []
          }
        };
      }
    }
  },
  computed: {

  },
  data () {
    return {
      fileVal: '',
      borderType: [{
        key: '无',
        value: 'none'
      }, {
        key: '实线',
        value: 'solid'
      }, {
        key: '点虚线',
        value: 'dotted'
      }, {
        key: '短虚线',
        value: 'dashed'
      }],
    };
  },
  methods: {
    delUrl (key) {
      this.value.border_image[key] = '';
      this.$message.success('删除成功');
    },
    upload (key) {
      this.actImg = key;
      this.$refs.file.click();
    },
    fileChange (evt) {
      const file = evt.target.files[0];
      this.files = file;
      this.fileUploadOss();
    },
    handleBorderType (val) {
      if (val === 'default' || val === '') {
        this.value.border.type = '';
      } else {
        this.value.border_image = {
          header: '',
          body: '',
          footer: '',
        };
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.fk-config {
  .buttons {
    /deep/ .el-button {
      padding: 0;
    }
  }
}
</style>
